import React, { FC, useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import qs from 'qs'
import Link from 'next/link'
import { userLogin } from 'redux/managers/user'
import Input from 'components/form/Input'
import Notification from 'components/NotificationBar'
import TestimonialsBig from 'components/TestimonialsBig'
import Button from 'components/Button'
import EmailInput from 'components/form/Email'
import { IStats } from 'redux/interfaces/stats'
import AuthUtils from 'utils/auth'
import LoginBanner from 'components/companies/LoginBanner'
import useTranslation from 'translations/hooks/useTranslation'

interface IProps {
  stats: IStats | null
  query?: {
    next?: string
    jobAddBanner?: string
  }
  userLogin: (signupData: any) => any
}

interface IState {
  loading: boolean
  email: string
  password: string
  error: boolean
}

const Login: FC<IProps> = (props) => {
  const [state, setState] = useState<IState>({
    loading: false,
    error: false,
    email: '',
    password: '',
  })
  const { t } = useTranslation()

  const handleEmail = (e) => {
    setState((prev) => ({ ...prev, email: e.target.value }))
  }

  const handlePassword = (e) => {
    setState((prev) => ({ ...prev, password: e.target.value }))
  }

  const isValid = () => {
    return state.email.length > 0 && state.password.length > 0
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setState((prev) => ({ ...prev, loading: true, error: false }))

    if (!isValid()) {
      return setState((prev) => ({ ...prev, loading: false, error: true }))
    }

    const { email, password } = state
    const { success, role } = await props.userLogin({
      email,
      password,
      role: 'standard',
    })

    if (success) {
      AuthUtils.handleRedirectOnLogin(role, props.query)
    } else {
      setState((prev) => ({ ...prev, loading: false, error: !success }))
    }
  }

  const { query } = props
  const { email, password, error, loading } = state

  return (
    <>
      {error && (
        <Notification
          text="Ups! Bol zadaný nesprávny e-mail alebo heslo."
          emoji="😳"
          error
        />
      )}

      <div className="container--gray-lightest full-height-content-with-testimonial flex-wrapper">
        <div className="container container--66">
          <h1 className="heading-1">{t('common.loginTitle')}</h1>

          <form onSubmit={handleSubmit} name="company-login">
            <EmailInput
              label="E-mail"
              name="email"
              placeholder=" "
              value={email}
              onChange={handleEmail}
              error={error}
              errorText={t('common.error.invalidData')}
            />

            <Input
              label={t('common.password')}
              name="password"
              type="password"
              placeholder=" "
              value={password}
              onChange={handlePassword}
              error={error}
              errorText={t('common.error.invalidData')}
              visibilityToggle
              required
            />

            <div className="overflow-hidden">
              <Link href="/forgot-password" className="link link--black forgot-password">
                {t('common.forgetYourPassword')}
              </Link>
            </div>

            <Button
              text={t('common.actions.login')}
              loading={loading}
              disabled={loading}
              containerClassName="container--no-padding margin-top-small"
              container
            />

            <p className="paragraph paragraph--small center margin-top-small">
              {t('common.noRegistrationYet')}{' '}
              <Link
                href={`/signup${qs.stringify(
                  {
                    next: query.next,
                  },
                  { addQueryPrefix: true }
                )}`}
                className="link link--black"
              >
                {t('common.registerYourself')}
              </Link>
            </p>
          </form>
        </div>
        <LoginBanner />
      </div>
      <TestimonialsBig white />
    </>
  )
}

const mapStateToProps = ({ stats }) => ({ stats })

const mapDispatchToProps = (dispatch) => ({
  userLogin: bindActionCreators(userLogin, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(Login)
